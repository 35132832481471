import { Component, OnInit, HostListener } from '@angular/core';
import { Http } from '@angular/http';
import { doAnimate } from 'src/app/shared/app.helpers';
import { Routes, RouterModule, Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  joinourteamlink: any;
  latitude: any;
  longitude: any;

  constructor(private _httpService: Http, private router: Router) { }

  ngOnInit() {
    //this.joinourteamlink = "https://corehr.hrcloud.com/groove/#/jobs";
    this.joinourteamlink = "https://recruiting.paylocity.com/recruiting/jobs/All/9e063217-bf19-463f-9b77-9b7caaad966d/Groove-National-Dance-Competition";
    this.latitude = 40.2423551;
    this.longitude = -74.0788938;
  }

  gotoRought(_url, _isHref) {
    doAnimate();
    setTimeout(() => {
      if (_isHref)
        window.open(_url, "_self");
      else {
        this.router.navigate([_url]);
      }
    }, 500);
  }

  onNavigate(link) {
    if (link != "" && link != null) {
      window.open(link);
    }
    else {
      console.log('err in footer link');
    }
  }

  @HostListener("window:scroll", []) onWindowScroll() {
    this.scrollFunction();
  }
  // When the user scrolls down 20px from the top of the document, show the button
  scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      document.getElementById("back2Top").style.display = "block";
    } else {
      document.getElementById("back2Top").style.display = "none";
    }
  }

  // When the user clicks on the button, scroll to the top of the document
  topFunction() {
    event.preventDefault();
    $("html, body").animate({ scrollTop: 0 }, 2000);
    return false;
  }

}
